<template>
  <div class="p-grid card" v-if="starting">
    <p-progress-spinner />
  </div>
  <div class="p-grid card" v-else>
    <div class="p-col-12">
      <p-toolbar class="p-mb-4">
        <template #left>
          <h5 class="p-m-0">Extrato Financeiro</h5>
        </template>

        <template #right v-if="$store.state.user?.admin">
          <x-foreign-user v-model="filter.user" placeholder="Beneficiado" />
        </template>
      </p-toolbar>
    </div>

    <div class="p-col-12 p-md-2 p-fluid">
      <div class="p-field">
        <label>Ciclo</label>
        <p-dropdown
          v-model="filter.ciclo"
          :options="userCiclos"
          optionLabel="value"
          optionValue="value"
          showClear
          placeholder="Todos"
        />
      </div>
    </div>
    <div class="p-col-12 p-md-2 p-fluid">
      <div class="p-field">
        <label>Tipo</label>
        <p-dropdown
          v-model="filter.tipo"
          :options="bonusTipos"
          showClear
          placeholder="Todos"
          optionLabel="descricao"
          optionValue="name"
          scrollHeight="240px"
        />
      </div>
    </div>
    <div class="p-col-12 p-md-2 p-fluid">
      <div class="p-field">
        <label>Nível</label>
        <p-dropdown
          v-model="filter.nivel"
          :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          showClear
          placeholder="Todos"
        />
      </div>
    </div>
    <div class="p-col p-fluid">
      <div class="p-field">
        <label>Nome</label>
        <p-inputtext v-model="filter.nome" @keypress.enter="consultar" />
      </div>
    </div>
    <div class="p-col p-fluid">
      <div class="p-field">
        <label>Pedido</label>
        <p-inputnumber v-model="filter.pedido" @keypress.enter="consultar" />
      </div>
    </div>
    <div class="p-col-12 p-d-flex p-ai-center p-jc-end">
      <p-button @click="consultar" label="Consultar" />
    </div>

    <p-divider />

    <div class="p-col-12">
      <p-datatable
        ref="dt"
        :value="result"
        dataKey="id"
        :loading="loading"
        :first="0"
        :lazy="true"
        :paginator="true"
        :rows="filter.rows"
        :totalRecords="filter.totalRecords"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 250, 500]"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        @page="onPage"
        @sort="onSort"
        responsiveLayout="stack"
        breakpoint="960px"
        stripedRows
      >
        <template #empty>
          <div class="p-text-center p-mt-5 p-mb-5">
            Nenhum registro encontrado!
          </div>
        </template>

        <p-column
          field="pedido.data_pagamento"
          header="Data"
          :sortable="true"
          style="min-width: 150px"
        >
          <template #body="{ data }">
            <span class="bold-mobile">{{
              new Date(
                data.data_pagamento || data.data_liberacao
              ).toLocaleString()
            }}</span>
          </template>
        </p-column>
        <p-column
          field="e.pedido_id"
          header="Pedido"
          sortable
          style="text-align: center"
        >
          <template #body="{ data }">
            {{ data.pedido_id || "-" }}
          </template>
        </p-column>
        <p-column field="user_pedido.name" header="Nome" sortable>
          <template #body="{ data }">
            <span class="bold-mobile">
              <a
                v-if="data.user_pedido_id"
                style="cursor: pointer"
                @click="userView.show(data.user_pedido_id)"
                >{{ data.name }}</a
              >
              <template v-else>{{ data.user_name }}</template>
            </span>
          </template>
        </p-column>
        <p-column field="e.tipo" header="Tipo" sortable>
          <template #body="{ data }">
            {{ getDescBonus(data.tipo) }}
          </template>
        </p-column>
        <p-column
          field="e.nivel_rede"
          header="Nível Bônus"
          sortable
          style="text-align: center"
        >
          <template #body="{ data }">
            {{ data.nivel_rede }}
          </template>
        </p-column>
        <p-column
          field="e.valor"
          header="Valor Bônus"
          sortable
          style="text-align: right"
        >
          <template #body="{ data }">
            <span class="bold-mobile">
              {{ $formatCurrency(parseFloat(data.valor)) }}
            </span>
          </template>
        </p-column>
        <p-column header="Status">
          <template #body="{ data }">
            {{ data.status }}
          </template>
        </p-column>
      </p-datatable>

      <div class="p-col-12" v-if="totais?.gerado > 0">
        <div class="p-text-right p-mb-2" style="font-size: 1.2em">
          Total Vendas Bonificadas:
          <b>{{ $formatCurrency(parseFloat(totais.total_vendas)) }}</b>
        </div>
        <div class="p-text-right p-mb-2" style="font-size: 1.2em">
          Total Bônus Gerado:
          <b>{{ $formatCurrency(parseFloat(totais.gerado)) }}</b>
        </div>
        <div class="p-text-right p-mb-2" style="font-size: 1.2em">
          Total Debitado:
          <b>{{ $formatCurrency(parseFloat(totais.debitado)) }}</b>
        </div>
        <div
          v-if="totais.reservado > 0"
          class="card p-p-3 p-text-left p-col-12"
          style="color: red"
        >
          <b>Observação:</b> Bonificações com status <b>Reservado</b> serão
          creditadas na virada de ciclo <b>somente</b> se a ativação mensal for
          realizada dentro do prazo. Se o prazo de ativação mensal for perdido,
          as bonificações reservadas serão redistribuídas para outros membros
          ativos na equipe ascendente.
        </div>
      </div>
    </div>
  </div>

  <x-user-view
    v-model:visible="userView.visible"
    :title="userView.title"
    :id="userView.id"
  ></x-user-view>
</template>

<script lang="ts">
import { http } from "@/api/rest";
import { useStore } from "@/store";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { bonusTipos } from "@/services/UserService";
import { useShowUser } from "@/components/UserView.vue";
import { getService as getServiceUser } from "@/services/UserService";
import { clone } from "@/utils/object";

export default defineComponent({
  setup() {
    const store = useStore();
    const userView = useShowUser();

    const filter = reactive({
      rows: 50,
      page: 0,
      sortField: "pedido.data_pagamento",
      sortOrder: -1,
      totalRecords: 0,
      q: "",
      ciclo: "",
      tipo: null,
      nivel: null,
      nome: null,
      pedido: null,
      user: null,
    });

    const starting = ref(true);
    const userCiclos = ref<any[]>([]);
    onMounted(async () => {
      try {
        const ciclos = (await http.get("api/user-ciclos")).data;
        userCiclos.value = ciclos.map((c: any) => ({
          value: `${String(c.ciclo_mes).padStart(2, "0")}/${c.ciclo_ano}`,
        }));
        if (!userCiclos.value.length) {
          const d = new Date();
          userCiclos.value.push({
            value: `${String(d.getMonth() + 1).padStart(
              2,
              "0"
            )}/${d.getFullYear()}`,
          });
        }
        filter.ciclo = userCiclos.value[0].value;
      } finally {
        starting.value = false;
      }
    });

    const loading = ref(false);
    const totais = ref<any>();
    const result = ref<any>([]);
    async function consultar() {
      loading.value = true;
      totais.value = undefined;
      try {
        const params = clone(filter);
        if (params.user) {
          params.user = params.user.id;
        }
        const resp = (
          await http.get("api/extrato-financeiro", {
            params,
          })
        ).data;
        result.value = resp.data;
        filter.totalRecords = resp.total;
        totais.value = resp.aggregation;
      } finally {
        loading.value = false;
      }
    }

    const firstConsulta = ref(true);
    watch(
      () => [filter.ciclo, filter.tipo, filter.nivel],
      () => {
        if (firstConsulta.value) {
          firstConsulta.value = false;
          return;
        }
        consultar();
      }
    );

    return {
      userView,
      starting,
      loading,
      consultar,
      result,
      filter,
      userCiclos,
      bonusTipos,
      totais,

      onPage(evt: any) {
        filter.rows = evt.rows;
        filter.page = evt.page;
        consultar();
      },
      onSort(evt: any) {
        filter.sortField = evt.sortField;
        filter.sortOrder = evt.sortOrder;
        consultar();
      },

      getDescBonus(name: string) {
        const r = bonusTipos.find((b) => b.name === name);
        if (name === "indicacao_pa") {
          return "Indicação PA";
        }
        return r ? r.descricao : name;
      },

      serviceUser: getServiceUser(),
    };
  },
});
</script>

<style scoped>
@media screen and (max-width: 960px) {
  ::v-deep(.bold-mobile) {
    font-weight: bold;
  }
}
</style>