
import { http } from "@/api/rest";
import { useStore } from "@/store";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { bonusTipos } from "@/services/UserService";
import { useShowUser } from "@/components/UserView.vue";
import { getService as getServiceUser } from "@/services/UserService";
import { clone } from "@/utils/object";

export default defineComponent({
  setup() {
    const store = useStore();
    const userView = useShowUser();

    const filter = reactive({
      rows: 50,
      page: 0,
      sortField: "pedido.data_pagamento",
      sortOrder: -1,
      totalRecords: 0,
      q: "",
      ciclo: "",
      tipo: null,
      nivel: null,
      nome: null,
      pedido: null,
      user: null,
    });

    const starting = ref(true);
    const userCiclos = ref<any[]>([]);
    onMounted(async () => {
      try {
        const ciclos = (await http.get("api/user-ciclos")).data;
        userCiclos.value = ciclos.map((c: any) => ({
          value: `${String(c.ciclo_mes).padStart(2, "0")}/${c.ciclo_ano}`,
        }));
        if (!userCiclos.value.length) {
          const d = new Date();
          userCiclos.value.push({
            value: `${String(d.getMonth() + 1).padStart(
              2,
              "0"
            )}/${d.getFullYear()}`,
          });
        }
        filter.ciclo = userCiclos.value[0].value;
      } finally {
        starting.value = false;
      }
    });

    const loading = ref(false);
    const totais = ref<any>();
    const result = ref<any>([]);
    async function consultar() {
      loading.value = true;
      totais.value = undefined;
      try {
        const params = clone(filter);
        if (params.user) {
          params.user = params.user.id;
        }
        const resp = (
          await http.get("api/extrato-financeiro", {
            params,
          })
        ).data;
        result.value = resp.data;
        filter.totalRecords = resp.total;
        totais.value = resp.aggregation;
      } finally {
        loading.value = false;
      }
    }

    const firstConsulta = ref(true);
    watch(
      () => [filter.ciclo, filter.tipo, filter.nivel],
      () => {
        if (firstConsulta.value) {
          firstConsulta.value = false;
          return;
        }
        consultar();
      }
    );

    return {
      userView,
      starting,
      loading,
      consultar,
      result,
      filter,
      userCiclos,
      bonusTipos,
      totais,

      onPage(evt: any) {
        filter.rows = evt.rows;
        filter.page = evt.page;
        consultar();
      },
      onSort(evt: any) {
        filter.sortField = evt.sortField;
        filter.sortOrder = evt.sortOrder;
        consultar();
      },

      getDescBonus(name: string) {
        const r = bonusTipos.find((b) => b.name === name);
        if (name === "indicacao_pa") {
          return "Indicação PA";
        }
        return r ? r.descricao : name;
      },

      serviceUser: getServiceUser(),
    };
  },
});
